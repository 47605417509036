.App {
  text-align: center;
  width: 100%
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  color: white;
  padding-top: 30px;
  padding-bottom: 20px;
}

.Menu-bar {
  background-color: white;
}

.Home-menu {
  margin-bottom: 0px!important;
}

.Page {
  max-width: 100%;
  overflow: hidden;
  align-items: center;
}

.Photo {
  width: 100%;
  object-fit: scale-down;
}

.Photo-venmo {
  width: 50%;
  object-fit: scale-down;
}

.Fund-box {
  height: 350px;
}

.Fund-box-mobile {
  height: 325px!important;
}

.Photo-fund {
  width: 90%;
  object-fit: scale-down;
  padding-bottom: 15px;
}

.Button {
  width: 250px;
  display: block;
  text-transform: unset!important;
}

.Fund {
  width: 200px;
}

.Photo-story {
    display: block;
    width: 25%;
}

.Photo-header {
    width: 100%;
    display: block;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    max-width: 100%;
    height: auto;
    border: 0;
}

.Photo-header-mobile {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 50%;
    height: auto;
    border: 0;
    transform: scale(1.7);
    padding-top: 70px;
    overflow: hidden;
}

.Photo-container {
  display: block;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  min-height: 620px;
}

.Home-page {
  background-color: #282c34;
  position: absolute;
  height: 100%;
}

.App-body {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: top;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Side-menu {
    background-color: #282c34;
    color: white;
    width: auto;
    height: 100%;
}

.App-link {
  color: #61dafb;
}

.Party-page {
  display: block;
  margin-left: 20rem;
  margin-right: 20rem;
}

.Page-title {
  font-size: xx-large;
}

.Page-subtitle {
  font-size: x-large;
}

.Party-title {
  font-size: x-large;
}

.Party-name {
  font-size: large;
}

.Content {
  font-size: large;
}

.HotelMap {
  width: "1200";
  height: "800";
}

.Venue-title {
  font-size: x-large;
  text-decoration: none;
  color:#282c34;
}

@media (min-width: 768px) {
    .web {
        display: none;
    }
}

@media (max-width: 992px) {
    .web {
        display: block;
    }
}

@media (max-width: 768px) {
    .mobile {
        display: none;
    }
}

@media (min-width: 992px) {
    .mobile {
        display: block;
    }
}
